/*
===============================================================================
    File        =   sign-in-app/src/reducers/account.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the reducer for the account slice which
                    will be used to mange account related state in the store
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.18  Moataz Khallaf    	Initial Creation
		1.1		2025.01.06	Daniel Rashidian	Handle reset password challenge
        -----------------------------------------------------------------------
===============================================================================
*/
import {
	SIGN_UP_ERROR,
	SIGN_UP_REQUEST,
	SIGN_UP_AWAITING_VERIFICATION,
	SIGN_UP_SUCCESS,
	CONFIRM_SIGN_UP_REQUEST,
	CONFIRM_SIGN_UP_SUCCESS,
	CONFIRM_SIGN_UP_ERROR,
	RESEND_VERIFICATION_REQUEST,
	RESEND_VERIFICATION_SUCCESS,
	RESEND_VERIFICATION_ERROR,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_ERROR,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	SIGN_IN_REQUEST,
	SIGN_IN_ERROR,
	SIGN_IN_SUCCESS,
	ANSWER_LOGIN_CHALLENGE_ERROR,
	ANSWER_LOGIN_CHALLENGE_REQUEST,
	ANSWER_LOGIN_CHALLENGE_SUCCESS,
	NAVIGATE_USER_TO_ACCOUNT_VERIFICATION,
	RESET_STATE,
	ANSWER_RESET_PASSWORD_CHALLENGE_SUCCESS,
} from "../actionTypes/account";
import { TWO_FACTOR_COMPLETE_SETUP_SUCCESS } from "../actionTypes/twoFactorAuth";
import {
	confirmSignUpRequest,
	signInRequest,
	signUpRequest,
	answerLoginResetPasswordChallengeRequest,
	answerLoginMFAChallengeRequest,
} from "../api/account";

let initialState = {
	userID: "",

	signUpLoading: false,
	signUpError: null,
	signUpStatus: "",

	confirmSignUpLoading: false,
	confirmSignUpError: null,

	resendVerificationLoading: false,
	resendVerificationError: null,

	forgotPasswordLoading: false,
	forgotPasswordError: null,
	changePasswordStatus: "",

	resetPasswordLoading: false,
	resetPasswordError: null,

	signInLoading: false,
	signInError: null,
	token: "",
	tokenExpirationTime: null,

	setUpTwoFactorAuth: false,
	authChallengeRequired: false,
	challengeSession: "",
	challengeName: "",

	answerLoginChallengeLoading: false,
	answerLoginChallengeError: null,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SIGN_UP_REQUEST:
			return {
				...state,
				signUpLoading: true,
				signUpError: null,
				signUpStatus: "",
			};

		case SIGN_UP_SUCCESS:
			return {
				...state,
				signUpLoading: false,
				signUpStatus: "Awaiting Verification",
			};

		case SIGN_UP_AWAITING_VERIFICATION:
			return {
				...state,
				signUpLoading: false,
				signUpStatus: "Awaiting Verification",
			};

		case SIGN_UP_ERROR:
			return {
				...state,
				signUpLoading: false,
				signUpError: action.payload,
			};

		case CONFIRM_SIGN_UP_REQUEST:
			return {
				...state,
				confirmSignUpLoading: true,
				confirmSignUpError: null,
			};

		case CONFIRM_SIGN_UP_SUCCESS:
			return {
				...state,
				confirmSignUpLoading: false,
				signUpStatus: "Verified",
			};

		case CONFIRM_SIGN_UP_ERROR:
			return {
				...state,
				confirmSignUpLoading: false,
				confirmSignUpError: action.payload,
			};

		case RESEND_VERIFICATION_REQUEST:
			return {
				...state,
				resendVerificationLoading: true,
				resendVerificationError: null,
			};

		case RESEND_VERIFICATION_SUCCESS:
			return {
				...state,
				resendVerificationLoading: false,
			};

		case RESEND_VERIFICATION_ERROR:
			return {
				...state,
				confirmSignUpLoading: false,
				confirmSignUpError: action.payload,
			};

		case FORGOT_PASSWORD_REQUEST:
			return {
				...state,
				forgotPasswordLoading: true,
				forgotPasswordError: null,
				resetPasswordError: null,
			};

		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				forgotPasswordLoading: false,
				changePasswordStatus: "Awaiting Verification",
			};

		case FORGOT_PASSWORD_ERROR:
			return {
				...state,
				forgotPasswordLoading: false,
				forgotPasswordError: action.payload,
			};

		case RESET_PASSWORD_REQUEST:
			return {
				...state,
				resetPasswordLoading: true,
				resetPasswordError: null,
			};

		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPasswordLoading: false,
				changePasswordStatus: "Success",
			};

		case RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPasswordLoading: false,
				resetPasswordError: action.payload,
			};

		case SIGN_IN_REQUEST:
			return {
				...state,
				signInLoading: true,
				signInError: null,
			};

		case SIGN_IN_SUCCESS:
			return {
				...state,
				userID: action.payload.userID,
				signInLoading: false,
				token: action.payload.token,
				tokenExpirationTime: action.payload.tokenExpirationTime,
				setUpTwoFactorAuth: action.payload.setUpTwoFactorAuth,
				authChallengeRequired: action.payload.authChallengeRequired,
				challengeSession: action.payload.challengeSession,
				challengeName: action.payload.challengeName,
			};

		case SIGN_IN_ERROR:
			return {
				...state,
				signInLoading: false,
				signInError: action.payload,
			};

		case NAVIGATE_USER_TO_ACCOUNT_VERIFICATION:
			return {
				...state,
				signInError: null,
				signUpStatus: "Awaiting Verification",
			};

		case RESET_STATE:
			return {
				...state,
				signUpLoading: false,
				signUpError: null,
				signUpStatus: "",

				confirmSignUpLoading: false,
				confirmSignUpError: null,

				resendVerificationLoading: false,
				resendVerificationError: null,

				forgotPasswordLoading: false,
				forgotPasswordError: null,
				changePasswordStatus: "",

				resetPasswordLoading: false,
				resetPasswordError: null,

				signInLoading: false,
				signInError: null,
				token: "",
				tokenExpirationTime: null,
			};

		case TWO_FACTOR_COMPLETE_SETUP_SUCCESS:
			return {
				...state,
				setUpTwoFactorAuth: false,
			};

		case ANSWER_LOGIN_CHALLENGE_REQUEST:
			return {
				...state,
				answerLoginChallengeLoading: true,
				answerLoginChallengeError: null,
			};

		case ANSWER_LOGIN_CHALLENGE_SUCCESS:
			return {
				...state,
				answerLoginChallengeLoading: false,
				token: action.payload.token,
				tokenExpirationTime: action.payload.tokenExpirationTime,
				authChallengeRequired: false,
			};

		case ANSWER_RESET_PASSWORD_CHALLENGE_SUCCESS:
			return {
				...state,
				answerLoginChallengeLoading: false,
				userID: action.payload.userID,
				signInLoading: false,
				token: action.payload.token,
				tokenExpirationTime: action.payload.tokenExpirationTime,
				setUpTwoFactorAuth: action.payload.setUpTwoFactorAuth,
				authChallengeRequired: action.payload.authChallengeRequired,
				challengeSession: action.payload.challengeSession,
				challengeName: action.payload.challengeName,
				};

		case ANSWER_LOGIN_CHALLENGE_ERROR:
			return {
				...state,
				answerLoginChallengeLoading: false,
				answerLoginChallengeError: action.payload,
			};

		default:
			return state;
	}
}

export function signUp(dispatch, signUpRequestModel) {
	signUpRequest(dispatch, signUpRequestModel);
}

export function confirmSignUp(dispatch, confirmSignUpRequestModel) {
	confirmSignUpRequest(dispatch, confirmSignUpRequestModel);
}

export function signIn(dispatch, signInRequestModel) {
	signInRequest(dispatch, signInRequestModel);
}

export function answerLoginResetPasswordChallenge(
	dispatch,
	answerLoginResetPasswordChallengeRequestModel
) {
	answerLoginResetPasswordChallengeRequest(dispatch, answerLoginResetPasswordChallengeRequestModel);
}

export function answerLoginMFAChallenge(
	dispatch,
	answerLoginChallengeRequestModel
) {
	answerLoginMFAChallengeRequest(dispatch, answerLoginChallengeRequestModel);
}

export default reducer;
