/*
===============================================================================
    File        =   sign-in-app/src/selectors/account.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the selectors for the account slice
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.08.06  Moataz Khallaf    	Initial Creation
		1.1		2025.01.06	Daniel Rashidian	Select challenge name 
        -----------------------------------------------------------------------
===============================================================================
*/

export const selectSignUpLoading = (state) => {
	return state.account.signUpLoading;
};
export const selectSignUpError = (state) => {
	return state.account.signUpError;
};
export const selectSignUpStatus = (state) => {
	return state.account.signUpStatus;
};

export const selectConfirmSignUpLoading = (state) => {
	return state.account.confirmSignUpLoading;
};
export const selectConfirmSignUpError = (state) => {
	return state.account.confirmSignUpError;
};

export const selectResendVerificationLoading = (state) => {
	return state.account.resendVerificationLoading;
};
export const selectResendVerificationError = (state) => {
	return state.account.resendVerificationError;
};

export const selectForgotPasswordLoading = (state) => {
	return state.account.forgotPasswordLoading;
};
export const selectForgotPasswordError = (state) => {
	return state.account.forgotPasswordError;
};
export const selectChangePasswordStatus = (state) => {
	return state.account.changePasswordStatus;
};

export const selectResetPasswordLoading = (state) => {
	return state.account.resetPasswordLoading;
};
export const selectResetPasswordError = (state) => {
	return state.account.resetPasswordError;
};

export const selectSignInLoading = (state) => {
	return state.account.signInLoading;
};
export const selectSignInError = (state) => {
	return state.account.signInError;
};
export const selectToken = (state) => {
	return state.account.token;
};
export const selectTokenExpirationTime = (state) => {
	return state.account.tokenExpirationTime;
};

export const selectAuthChallengeRequired = (state) => {
	return state.account.authChallengeRequired;
};
export const selectChallengeSession = (state) => {
	return state.account.challengeSession;
};
export const selectChallengeName = (state) => {
	return state.account.challengeName;
};
export const selectSetUpTwoFactorAuth = (state) => {
	return state.account.setUpTwoFactorAuth;
};
export const selectUserID = (state) => {
	return state.account.userID;
};
export const selectAnswerLoginChallengeLoading = (state) => {
	return state.account.answerLoginChallengeLoading;
};
export const selectAnswerLoginChallengeError = (state) => {
	return state.account.answerLoginChallengeError;
};
