/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/SignIn/SignIn.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the SignIn component which is used to
                    render the sign in form and handle the sign in logic
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.19  Moataz Khallaf    	Initial Creation
		1.1	 	2024.07.03  Moataz Khallaf    	Added font-end logic for two factor authentication
		1.2     2024.06.20  Moataz Khallaf    	integrate backend calls
		1.3		2025.01.06	Daniel Rashidian	Reset password challenge
        -----------------------------------------------------------------------
===============================================================================
*/
import "../account.css";
import {
	AppLogo,
	SignInForm,
	SignInAuthen,
	SignInAuthMethod,
	AuthCodeInput,
	ResetPasswordMain,
} from "../../../ui-components";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
	signIn as signInUser,
	answerLoginResetPasswordChallenge,
	answerLoginMFAChallenge,
} from "../../../reducers/account";
import {
	navigateUserToAccountVerification,
	resetState,
} from "../../../actions/account";
import StartHiring from "../StartHiring/StartHiring";
import Loader from "../../../utils/loader/Loader";
import Error from "../../../utils/error/Error";
import { error } from "../../../utils/error/ErrorMessage";
import TwoFactorQR from "./TwoFactorQR";
import { setupMFA, completeSetupMFA } from "../../../reducers/twoFactorAuth";

import {
	selectSignInLoading,
	selectSignInError,
	selectToken,
	selectTokenExpirationTime,
	selectChallengeSession,
	selectChallengeName,
	selectAuthChallengeRequired,
	selectSetUpTwoFactorAuth,
	selectUserID,
	selectAnswerLoginChallengeLoading,
	selectAnswerLoginChallengeError,
} from "../../../selectors/account";

import {
	selectTwoFactorPrivateKey,
	selectTwoFactorKeyCompleteSetupLoading,
	selectTwoFactorKeyCompleteSetupError,
	selectTwoFactorKeySetupLoading,
	selectTwoFactorKeySetupError,
} from "../../../selectors/twoFactorAuth";

function SignIn() {
	const [errors, setErrors] = useState([]);

	const [authMethod, setAuthMethod] = useState("");
	const [enableAuth, setEnableAuth] = useState(false);
	const [authCode, setAuthCode] = useState("");
	const [companyCode, setCompanyCode] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [companyCodeError, setCompanyCodeError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [authCodeError, setAuthCodeError] = useState("");
	const [signInState, setSignInState] = useState("signIn");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [newPasswordError, setNewPasswordError] = useState("");
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

	const navigate = useNavigate();

	const userID = useSelector((state) => selectUserID(state));
	const signInLoading = useSelector((state) => selectSignInLoading(state));
	const signInError = useSelector((state) => selectSignInError(state));
	const token = useSelector((state) => selectToken(state));

	const tokenExpirationTime = useSelector((state) =>
		selectTokenExpirationTime(state)
	);
	const secretCode = useSelector((state) => selectTwoFactorPrivateKey(state));
	const challengeSession = useSelector((state) =>
		selectChallengeSession(state)
	);
	const challengeName = useSelector((state) =>
		selectChallengeName(state)
	);
	const authChallengeRequired = useSelector((state) =>
		selectAuthChallengeRequired(state)
	);
	const setUpTwoFactorAuth = useSelector((state) =>
		selectSetUpTwoFactorAuth(state)
	);
	const answerLoginChallengeLoading = useSelector((state) =>
		selectAnswerLoginChallengeLoading(state)
	);
	const answerLoginChallengeError = useSelector((state) =>
		selectAnswerLoginChallengeError(state)
	);
	const twoFactorKeyCompleteSetupLoading = useSelector((state) =>
		selectTwoFactorKeyCompleteSetupLoading(state)
	);
	const twoFactorKeyCompleteSetupError = useSelector((state) =>
		selectTwoFactorKeyCompleteSetupError(state)
	);
	const twoFactorKeySetupLoading = useSelector((state) =>
		selectTwoFactorKeySetupLoading(state)
	);

	const dispatch = useDispatch();

	const removeError = (index) => {
		setErrors((errors) => errors.filter((_, i) => i !== index));
	};

	const addError = (newError) => {
		setErrors((errors) => {
			if (
				!errors.some(
					(error) =>
						error.field === newError.field &&
						error.message === newError.message
				)
			) {
				return [...errors, newError];
			}
			return errors;
		});
	};

	useEffect(() => {
		if (errors.length) {
			const timer = setTimeout(() => {
				removeError(0);
			}, 5000);

			return () => clearTimeout(timer);
		}
	}, [errors]);

	const handleCompanyCodeChange = useCallback((event) => {
		setCompanyCode(event.target.value);
	}, []);

	const handleEmailChange = useCallback((event) => {
		setEmail(event.target.value);
	}, []);

	const handlePasswordChange = useCallback((event) => {
		setPassword(event.target.value);
	}, []);

	const handleAuthCodeChange = useCallback((event) => {
		setAuthCode(event.target.value);
	}, []);

	const location = useLocation();

	useEffect(() => {
		if (location.state?.email) {
			setEmail(location.state.email);
		}
		if (location.state?.password) {
			setPassword(location.state.password);
		}
		if (location.state?.companyCode) {
			setCompanyCode(location.state.companyCode);
		}
	}, [location]);

	// Handle sign in errors
	useEffect(() => {
		if (signInError) {
			if (
				signInError.status === 401 &&
				signInError.message === "Invalid login credentials"
			) {
				setEmailError("Invalid credentials");
				setPasswordError("Invalid credentials");
				setCompanyCodeError("Invalid credentials");
				addError(error("email", "Invalid credentials"));
				addError(error("password", ""));
				addError(error("companyCode", ""));
			} else if (signInError.status === 429) {
				setEmailError(
					"Too many sign in attempts. Please try again later."
				);
				addError(
					error(
						"email",
						"Too many sign in attempts. Please try again later."
					)
				);
			}

			// Don't expect this to happen
			else if (signInError.status === 403) {
				setPasswordError("Password reset required");
				addError(
					error(
						"email",
						"Too many sign in attempts. Please try again later."
					)
				);
			}

			// Account is still in verification state, navigate to sign up verification
			else if (signInError.status === 401) {
				dispatch(navigateUserToAccountVerification());
				navigate("/signup", { state: { email: email } });
			}
		}
	}, [signInError, dispatch, email, navigate]);

	// Handle two factor auth setup errors
	useEffect(() => {
		if (twoFactorKeyCompleteSetupError) {
			if (
				twoFactorKeyCompleteSetupError.status === 401 &&
				(twoFactorKeyCompleteSetupError.message ===
					"Invalid MFA code" ||
					twoFactorKeyCompleteSetupError.message ===
						"MFA code expired" ||
					twoFactorKeyCompleteSetupError.message === "Invalid code")
			) {
				setAuthCodeError(twoFactorKeyCompleteSetupError.message);
				addError(
					error("authCode", twoFactorKeyCompleteSetupError.message)
				);
			} else if (
				twoFactorKeyCompleteSetupError.status === 429 &&
				twoFactorKeyCompleteSetupError.message ===
					"Too many requests. Please try again later."
			) {
				setAuthCodeError(twoFactorKeyCompleteSetupError.message);
				addError(
					error("authCode", twoFactorKeyCompleteSetupError.message)
				);
			} else if (twoFactorKeyCompleteSetupError.status === 500) {
				setAuthCodeError("An error occurred. Please try again later.");
				addError(
					error(
						"authCode",
						"An error occurred. Please try again later."
					)
				);
			}
		}
	}, [twoFactorKeyCompleteSetupError]);

	// Handle login challenge errors
	useEffect(() => {
		if (answerLoginChallengeError) {
			if (
				answerLoginChallengeError.status === 401 &&
				(answerLoginChallengeError.message === "Invalid MFA code" ||
					answerLoginChallengeError.message === "MFA code expired")
			) {
				setAuthCodeError(answerLoginChallengeError.message);
				addError(error("authCode", answerLoginChallengeError.message));
			} else if (
				answerLoginChallengeError.status === 429 &&
				answerLoginChallengeError.message ===
					"Too many requests. Please try again later."
			) {
				if (challengeName === "NEW_PASSWORD_REQUIRED") {
					setNewPasswordError(answerLoginChallengeError.message);
					setConfirmNewPasswordError(answerLoginChallengeError.message);
				}

				else if (challengeName === "SOFTWARE_TOKEN_MFA") {
					setAuthCodeError(answerLoginChallengeError.message);
				}
				addError(error("authCode", answerLoginChallengeError.message));
			} else if (answerLoginChallengeError.status === 500) {
				if (challengeName === "NEW_PASSWORD_REQUIRED") {
					setNewPasswordError("An error occurred. Please try again later.");
					setConfirmNewPasswordError("An error occurred. Please try again later.");
				}
				else if  (challengeName === "SOFTWARE_TOKEN_MFA") {
					setAuthCodeError("An error occurred. Please try again later.");
				}
		
				addError(
					error(
						"authCode",
						"An error occurred. Please try again later."
					)
				);
			}
		}
	}, [answerLoginChallengeError, challengeName]);

	// post login success, push to dashboard
	useEffect(() => {
		console.log(token, setUpTwoFactorAuth, authChallengeRequired);
		if (token && !setUpTwoFactorAuth && !authChallengeRequired) {
			if (new Date().getTime() < tokenExpirationTime) {
				const queryParams = new URLSearchParams(location.search);
				const redirectUrl = queryParams.get("redirect_url");
				localStorage.setItem("token", token);
				if (redirectUrl) {
					window.location.href = `${redirectUrl}?token=${token}`;
				} else {
					window.location.href = "/dashboard";
				}
			}
		}
	}, [
		token,
		tokenExpirationTime,
		location.search,
		setUpTwoFactorAuth,
		authChallengeRequired,
	]);

	const validateEmail = useCallback(() => {
		if (email.trim() === "") {
			setEmailError(
				"Please enter the email registered with your account"
			);
			addError(
				error(
					"email",
					"Please enter the email registered with your account"
				)
			);
			return false;
		}
		setEmailError("");
		return true;
	}, [email]);

	const validatePassword = useCallback(() => {
		if (password.trim() === "") {
			setPasswordError("Please enter your password");
			addError(error("password", "Please enter your password"));
			return false;
		}
		setPasswordError("");
		return true;
	}, [password]);

	const validateCompanyCode = useCallback(() => {
		if (companyCode.trim() === "") {
			setCompanyCodeError("Please enter your company code");
			addError(error("companyCode", "Please enter your company code"));
			return false;
		}
		setCompanyCodeError("");
		return true;
	}, [companyCode]);

	const validateAuthCode = useCallback(() => {
		const VALID_CODE_PATTERN = /^\d{6}$/;

		if (authCode.trim() === "") {
			setAuthCodeError("Please enter the code on your authenticator app");
			addError(
				error(
					"authCode",
					"Please enter the code on your authenticator app"
				)
			);
			return false;
		} else if (!VALID_CODE_PATTERN.test(authCode)) {
			setAuthCodeError("Six digit code required.");
			addError(error("authCode", "Six digit code required."));
			return false;
		}
		setAuthCodeError("");
		return true;
	}, [authCode]);

	    const validateNewPassword = useCallback(() => {
        if (newPassword === '') {
            setNewPasswordError('Password is required.')
            return false;
        }

        if (newPassword.trim() !== newPassword) {
            setNewPasswordError('Password cannot begin or end with a space.')
            return false;
        }

        if (newPassword.length < 10) {
            setNewPasswordError("Password must be at least 10 characters long")
            return false;
        }
        if (!/[0-9]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one number");
            return false;
        }
  
        if (!/[A-Z]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one uppercase letter");
            return false;
        }
        if (!/[a-z]/.test(newPassword)) {
            setNewPasswordError("Password must include at least one lowercase letter");
            return false;
        }

        const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\"\!@#%&\/\\,><\':;|_~\`+= -]{10,}$/;
        if (!VALID_PASSWORD_REGEX.test(newPassword)) {
            setNewPasswordError("Password must include at least one special symbol");
            return false;
        }

        setNewPasswordError('');
        return true;
    }, [newPassword]);

    const validateConfirmNewPassword = useCallback(() => {
        if (confirmNewPassword === '') {
            setConfirmNewPasswordError('Please confirm your new password.');
            return false;
        }
        if (newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmNewPasswordError('');
        return true;
    }, [confirmNewPassword, newPassword]);

    const handleChangePasswordChallengeSession = useCallback(async (event) => {
        event.preventDefault();

        const validations = [
            validateNewPassword,
            validateConfirmNewPassword
        ];

        const allValid = validations.map(validation => validation()).every(result => result)

        if (allValid) {
			const changePasswordChallengeSessionRequestBody = {
				challenge_session: challengeSession,
				user_id: userID,
				new_password: newPassword,
				confirm_new_password: confirmNewPassword,
			};

            answerLoginResetPasswordChallenge(dispatch, changePasswordChallengeSessionRequestBody)
        }

    }, [validateNewPassword, validateConfirmNewPassword, dispatch, newPassword, confirmNewPassword, challengeSession, userID]);

	// set up auth check
	useEffect(() => {
		if (token && setUpTwoFactorAuth) {
			setSignInState("setUpAuth");
		}
	}, [token, setUpTwoFactorAuth]);

	const handleSignIn = useCallback(
		async (event) => {
			event.preventDefault();

			const validations = [
				validateEmail,
				validatePassword,
				validateCompanyCode,
			];

			const allValid = validations
				.map((validation) => validation())
				.every((result) => result);

			if (allValid) {
				const signInRequestBody = {
					email: email,
					password: password,
					organization_code: companyCode,
				};
				signInUser(dispatch, signInRequestBody);
			}
		},
		[
			email,
			password,
			companyCode,
			validateEmail,
			validatePassword,
			validateCompanyCode,
			dispatch,
		]
	);

	// enable auth check if secret code exists
	useEffect(() => {
		if (secretCode) {
			setEnableAuth(true);
		}
	}, [secretCode]);

	const handleSetupMFA = useCallback(() => {
		const twoFactorSetupRequestModel = {
			access_token: token,
		};

		setupMFA(dispatch, twoFactorSetupRequestModel);
	}, [dispatch, token]);

	useEffect(() => {
		if (challengeSession && authChallengeRequired) {
			if (challengeName === "NEW_PASSWORD_REQUIRED") {
				setSignInState("newPasswordChange");
			}

			else if (challengeName === "SOFTWARE_TOKEN_MFA") {
				setSignInState("authCodeInput");
			}
		}
	}, [challengeSession, authChallengeRequired, challengeName]);

	const handleCompleteSetupMFA = useCallback(() => {
		const validation = validateAuthCode();

		if (validation) {
			const completeTwoFactorVerifyRequestModel = {
				access_token: token,
				code: authCode,
			};

			completeSetupMFA(dispatch, completeTwoFactorVerifyRequestModel);
		}
	}, [dispatch, token, authCode, validateAuthCode]);

	const handleMFAChallengeSession = useCallback(() => {
		const validation = validateAuthCode();

		if (validation) {
			const ModelMFAChallengeResponseRequest = {
				challenge_session: challengeSession,
				challenge_code: authCode,
				user_id: userID,
			};

			answerLoginMFAChallenge(dispatch, ModelMFAChallengeResponseRequest);
		}
	}, [challengeSession, authCode, dispatch, userID, validateAuthCode]);

	const navigateToForgotPassword = useCallback(() => {
		dispatch(resetState());
		navigate("/forgotpassword");
	}, [navigate, dispatch]);

	const navigateToSignUp = useCallback(() => {
		dispatch(resetState());
		navigate("/signup");
	}, [navigate, dispatch]);

	const signInFormOverrides = useMemo(() => {
		return {
			RhealizeLogo: {
				children: <AppLogo />,
			},

			SignInForm: {
				height: "100vh",
				width: "50vw",
			},

			Button: {
				color: "white",
				onClick: handleSignIn,
			},

			Email585734772: {
				value: companyCode,
				onChange: handleCompanyCodeChange,
				hasError: companyCodeError ? true : false,
			},

			Email585734771: {
				value: email,
				onChange: handleEmailChange,
				hasError: emailError ? true : false,
			},

			PasswordField: {
				value: password,
				onChange: handlePasswordChange,
				hasError: passwordError ? true : false,
			},

			"Forgot password?": {
				children: (
					<span
						onClick={navigateToForgotPassword}
						style={{
							fontWeight: "600",
							color: "rgba(49,91,155,1)",
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						Forgot Password?
					</span>
				),
			},

			"Not registered? Create an account.": {
				children: (
					<div>
						<span>Not registered? </span>
						<span
							onClick={navigateToSignUp}
							style={{
								fontWeight: "600",
								color: "rgba(49,91,155,1)",
								textDecoration: "none",
								cursor: "pointer",
							}}
						>
							Create an account.
						</span>
					</div>
				),
			},
		};
	}, [
		companyCode,
		companyCodeError,
		email,
		emailError,
		password,
		passwordError,
		handleCompanyCodeChange,
		handleEmailChange,
		handlePasswordChange,
		handleSignIn,
		navigateToForgotPassword,
		navigateToSignUp
	]);

	const SignInAuthenOverrides = useMemo(() => {
		return {
			SignInAuthen: {
				height: "100vh",
				width: "50vw",
			},

			RhealizeLogo: {
				children: <AppLogo />,
			},

			Button: {
				color: "white",
				onClick: () => {
					setSignInState("setUpAuthMethod");
				},
			},
		};
	}, [setSignInState]);

	const SignInAuthMethodOverrides = useMemo(() => {
		return {
			SignInAuthMethod: {
				height: "100vh",
				width: "50vw",
			},

			RhealizeLogo: {
				children: <AppLogo />,
			},

			Button: {
				color: "white",
				onClick: () => {
					handleSetupMFA();
				},

				isDisabled: authMethod.trim() === "",
			},

			Radio585734798: {
				onChange: (e) => {
					setAuthMethod(e.target.value);
				},
			},

			Radio585734799: {
				isDisabled: true,
			},
		};
	}, [authMethod, handleSetupMFA]);

	const AuthCodeInputOverrides = useMemo(() => {
		return {
			AuthCodeInput: {
				height: "100vh",
				width: "50vw",
				justifyContent: "center",
				alignItems: "center",
			},

			RhealizeLogo: {
				children: <AppLogo />,
			},

			"Enter the code sent to your email address": {
				children: "Please enter the code on your authenticator app.",
			},

			TextField: {
				value: authCode,
				onChange: handleAuthCodeChange,
				hasError: authCodeError ? true : false,
			},

			Button: {
				color: "white",
				onClick: () => {
					if (authChallengeRequired && !setUpTwoFactorAuth) {
						handleMFAChallengeSession();
					} else {
						handleCompleteSetupMFA();
					}
				},
			},
		};
	}, [, authCode, handleAuthCodeChange, authCodeError, authChallengeRequired, setUpTwoFactorAuth, handleCompleteSetupMFA, handleMFAChallengeSession]);

	const resetPasswordOverrides = useMemo(() => {
		return {
		AppLogo: {
			children: <AppLogo />,
		},
		"TextField": {
			display: "none",
		},

		"PasswordField694911510": {
			value: newPassword,
			onChange: (event) => {
				setNewPassword(event.target.value);
			},
			hasError: newPasswordError ? true : false,
			errorMessage: newPasswordError
		},

		"PasswordField694911512": {
			value: confirmNewPassword,
			onChange: (event) => {
				setConfirmNewPassword(event.target.value);
			},
			hasError: confirmNewPasswordError ? true : false,
			errorMessage: confirmNewPasswordError
		},
		
		"Button694911514": {
			onClick: handleChangePasswordChallengeSession

		},

		"Button694911515": {
			display: "none"
		}
	};
}, [newPassword, newPasswordError, confirmNewPassword, confirmNewPasswordError, handleChangePasswordChallengeSession, ]);

	return (
		<div className="container">
			<div className="side-by-side">
				<StartHiring />
				<div className="form-container">
					<Error errors={errors} onClose={removeError}></Error>

					{(signInLoading ||
						answerLoginChallengeLoading ||
						twoFactorKeyCompleteSetupLoading ||
						twoFactorKeySetupLoading) && (
						<Loader
							width="10rem"
							height="10rem"
							transform="-50%, -100%"
						/>
					)}

					{signInState === "signIn" && (
						<SignInForm overrides={signInFormOverrides} />
					)}
					{signInState === "setUpAuth" && (
						<SignInAuthen overrides={SignInAuthenOverrides} />
					)}
					{signInState === "setUpAuthMethod" && (
						<SignInAuthMethod
							overrides={SignInAuthMethodOverrides}
						/>
					)}
					{enableAuth && (
						<>
							<div className="tos-privacy-modal">
								<div className="modal-backdrop"></div>
								<TwoFactorQR
									email={email}
									qrCode={secretCode}
									setSignInState={setSignInState}
									setEnableAuth={setEnableAuth}
								/>
							</div>
						</>
					)}
					{signInState === "authCodeInput" && (
						<AuthCodeInput overrides={AuthCodeInputOverrides} />
					)}
					{signInState === "newPasswordChange" && (
						<ResetPasswordMain overrides={resetPasswordOverrides} />
					)}
				</div>
			</div>
		</div>
	);
}

export default SignIn;
