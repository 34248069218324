/*
===============================================================================
    File        =   sign-in-app/src/actionTypes/account.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   This file contains the action types for the account reducer
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.19  Moataz Khallaf      Initial Creation
        1.1     2025.01.06  Daniel Rashidian    Action types for reset password challenge
        -----------------------------------------------------------------------
===============================================================================
*/
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_AWAITING_VERIFICATION =
	"SIGN_UP_USER_AWAITING_VERIFICATION";

export const CONFIRM_SIGN_UP_REQUEST = "CONFIRM_SIGN_UP_REQUEST";
export const CONFIRM_SIGN_UP_SUCCESS = "CONFIRM_SIGN_UP_SUCCESS";
export const CONFIRM_SIGN_UP_ERROR = "CONFIRM_SIGN_UP_ERROR";

export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_ERROR = "RESEND_VERIFICATION_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";

export const ANSWER_LOGIN_CHALLENGE_REQUEST = "ANSWER_LOGIN_CHALLENGE_REQUEST";
export const ANSWER_LOGIN_CHALLENGE_SUCCESS = "ANSWER_LOGIN_CHALLENGE_SUCCESS";
export const ANSWER_LOGIN_CHALLENGE_ERROR = "ANSWER_LOGIN_CHALLENGE_ERROR";
export const ANSWER_RESET_PASSWORD_CHALLENGE_SUCCESS = "ANSWER_RESET_PASSWORD_CHALLENGE_SUCCESS";

export const NAVIGATE_USER_TO_ACCOUNT_VERIFICATION =
	"NAVIGATE_USER_TO_ACCOUNT_VERIFICATION";

export const RESET_STATE = "RESET_STATE";
