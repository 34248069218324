/*
===============================================================================
    File        =   sign-in-app/src/pages/Account/SignUp/Verification.js
    Client      =   Rhealize
    Project     =   Ikimy Platform
    Purpose     =   Sign Up Verification Component
                    
===============================================================================
    Revision History
        -----------------------------------------------------------------------
        Version Date        Author              Comments
        -----------------------------------------------------------------------
        1.0     2024.06.19  Moataz Khallaf    	Initial Creation
        1.1     2024.08.29  Crush Bate        	Uncommented resend verification
		1.2		2025.01.06	Daniel Rashidian	Bug fix
        -----------------------------------------------------------------------
===============================================================================
*/
import { SigninVerification } from "../../../ui-components";
import { useState, useEffect, useCallback } from "react";
import { confirmSignUp as confirmSignUpUser } from "../../../reducers/account";
import { resendVerification as resendVerificationForUser } from "../../../actions/reducers/reducers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../utils/loader/Loader";

const selectConfirmSignUpLoading = (state) => {
	return state.account.confirmSignUpLoading;
};
const selectConfirmSignUpError = (state) => {
	return state.account.confirmSignUpError;
};
//const selectSignUpStatus = (state) => {return state.account.signUpStatus};

const selectResendVerificationLoading = (state) => {
	return state.account.resendVerificationLoading;
};
const selectResendVerificationError = (state) => {
	return state.account.resendVerificationError;
};

function Verification(props) {
	const { email } = props;

	const confirmSignUpLoading = useSelector((state) =>
		selectConfirmSignUpLoading(state)
	);
	const confirmSignUpError = useSelector((state) =>
		selectConfirmSignUpError(state)
	);
	const resendVerificationLoading = useSelector((state) =>
		selectResendVerificationLoading(state)
	);
	const resendVerificationError = useSelector((state) =>
		selectResendVerificationError(state)
	);

	const dispatch = useDispatch();

	const [code, setCode] = useState("");
	const [codeError, setCodeError] = useState("");

	const [verificationOverrides, setVerificationOverrides] = useState({});

	const handleCodeChange = (event) => {
		setCode(event.target.value);
	};

	const validateCode = useCallback(() => {
		if (code.trim() === "") {
			setCodeError("Code is required.");
		}

		const VALID_CODE_PATTERN = /^\d{6}$/;
		if (!VALID_CODE_PATTERN.test(code)) {
			setCodeError("Six digit code required.");
			return false;
		}
		setCodeError("");
		return true;
	}, [code]);

	const handleConfirm = useCallback(
		async (event) => {
			event.preventDefault();

			const validations = [validateCode];

			const allValid = validations
				.map((validation) => validation())
				.every((result) => result);

			if (allValid) {
				const confirmSignUpRequestBody = {
					email: email,
					code: code,
				};
				confirmSignUpUser(dispatch, confirmSignUpRequestBody);
			}
		},
		[dispatch, code, email, validateCode]
	);

	const handleResendCode = useCallback(
		async (event) => {
			event.preventDefault();

			const resendVerificationRequestBody = {
				email: email,
			};
			resendVerificationForUser(dispatch, resendVerificationRequestBody);
		},
		[email, dispatch]
	);

	useEffect(() => {
		if (confirmSignUpError) {
			if (
				confirmSignUpError.status === 401 &&
				confirmSignUpError.message === "Code is not valid."
			) {
				setCodeError("This code is not valid. Please try again.");
			} else if (confirmSignUpError.status === 429) {
				setCodeError(
					"Too many incorrect attempts. Please try again later."
				);
			} else if (confirmSignUpError.status === 410) {
				setCodeError(
					"This code is expired. Please request a new verification code to be sent."
				);
			} else if (confirmSignUpError.status === 403) {
				setCodeError("User already verified.");
			}
		} else if (resendVerificationError) {
			if (resendVerificationError.status === 429) {
				setCodeError(
					"Attempted too many requests. Please try again later."
				);
			} else if (resendVerificationError.status === 403) {
				setCodeError("User already verified.");
			}
		}
	}, [confirmSignUpError, resendVerificationError]);

	useEffect(() => {
		const newVerificationOverrides = {
			...verificationOverrides,

			"Your code is on the way. To log in, enter the code we emailed to damitimo@yahoo.com. It may take a minute to arrive.":
				{
					children: `Your code is on the way. To log in, enter the code we emailed to ${email}. It may take a minute to arrive.`,
				},

			TextField: {
				value: code,
				onChange: handleCodeChange,
				hasError: codeError ? true : false,
				errorMessage: codeError,
			},

			Button62698681: {
				onClick: handleConfirm,
			},

			Button62698682: {
				onClick: handleResendCode,
			},
		};

		if (
			JSON.stringify(newVerificationOverrides) !==
			JSON.stringify(verificationOverrides)
		) {
			setVerificationOverrides(newVerificationOverrides);
		}
	}, [
		email,
		verificationOverrides,
		code,
		codeError,
		handleConfirm,
		handleResendCode,
	]);

	return (
		<>
			{(confirmSignUpLoading || resendVerificationLoading) && (
				<Loader width="50%" height="50%" transform="-50%, -100%" />
			)}
			{
				<SigninVerification
					overrides={verificationOverrides}
				></SigninVerification>
			}
		</>
	);
}

export default Verification;
